export const stagingConfig = {
  mixpanel: {
    apiHost: "https://metrics.spill.chat",
    token: "4293a69fc94d9b4a23cddebce23569d4",
    sendEvents: true,
  },
  acuity3: {
    appointmentTypeIds: {
      teamPlanSeries: "46090564",
      teamPlanSingle: "46090594",
    },
    ownerId: "23547353",
    therapySessionAcuityAppointmentTypeId: "41289382",
    fieldIds: {
      spillUserId: "7931425",
      pronouns: "12880090",
      approximateLocation: "12880092",
      emergencyContactName: "12880093",
      emergencyContactNumber: "12880095",
    },
  },
  google: {
    clientId:
      "246272913629-6tciaaod2jbsmm0cv6c5uqehf8aqhdt0.apps.googleusercontent.com",
    analyticsTrackingId: "G-9T1XGHT23N",
  },
  unleash: {
    clientKey: "isxSZD8y7laOIsq/l0eAgh4Txxy5pMEZlQzNeolzclrnkedG2KEiOA==",
  },
}
